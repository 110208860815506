import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomePageView.vue"),
    meta: {
      title: "Home Page",
      metaTags: [
        {
          name: "description",
          content:
            "Experts in asbestos removal, ventilation cleaning, and facilities management, providing clients with a cost effective solutions that minimises disruption and meets health and safety standards",
        },
        {
          name: "keywords",
          content:
            "asbestos, asbestos removal, asbestos remediation, ductwork cleaning, ventilation hygiene, facilities management, innovation, health and safety, building refurbishment, fan maintenance, fire and smoke damper maintenance, global, nationwide, bosiet, non-licence, notifiable, leadership, carbon neutral, sustainability",
        },
      ],
    },
  },
  {
    path: "/brand-guidelines",
    name: "brand guidelines",
    component: () => import("../views/BrandGuidelinesView.vue"),
    meta: {
      title: "DCUK - Brand Guidelines",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Brand Guidelines",
        },
      ],
    },
  },
  {
    path: "/our-company",
    name: "our company",
    component: () => import("../views/OurCompanyView.vue"),
    meta: {
      title: "Our Company",
      metaTags: [
        {
          name: "description",
          content:
            "DCUK FM established in 1998, with Managing Director, Nigel Jones at the helm since the outset, many of our seasoned management members have been part of this journey right from those early days",
        },
        {
          name: "keywords",
          content:
            "ems group, Vectra n jones, rospa, investors in people, British safety council, expertise, experts, heathrow, southwark, parliament, transport for London, British library, kings college, university of Sheffield, network rail, hospital, asbestos, asbestos removal, asbestos remediation, ductwork cleaning, ventilation hygiene, facilities management, innovation, health and safety, building refurbishment, fan maintenance, fire and smoke damper maintenance, global, nationwide, bosiet, non-licence, notifiable, leadership, carbon neutral, sustainability",
        },
      ],
    },
  },
  {
    path: "/technology",
    name: "technology",
    component: () => import("../views/TechPageView.vue"),
    meta: {
      title: "DCUK - Technology",
      metaTags: [
        {
          name: "description",
          content:
            "Harnessing the power of advanced sensors coupled with bespoke software, we offer innovative solutions tailored to meet the specific needs of our clients",
        },
        {
          name: "keywords",
          content:
            "technology, software, hardware, sensors, imos, site management, vanguard sensor, vst, lexi sensor, x bionic, sensor chip, innovation, matterport, spaceplus, asset management, asset monitoring, compliance, asset tagging",
        },
      ],
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactUsView.vue"),
    meta: {
      title: "DCUK - Contact",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Contact",
        },
      ],
    },
  },
  {
    path: "/careers",
    name: "careers",
    component: () => import("../views/CareersPageView.vue"),
    meta: {
      title: "DCUK - Careers",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Careers",
        },
      ],
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy policy",
    component: () => import("../views/PrivacyPolicyView.vue"),
    meta: {
      title: "DCUK - Privacy Policy",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Privacy Policy",
        },
      ],
    },
  },
  // {
  //   path: "/legislation",
  //   name: "legislation and regulations",
  //   component: () => import("../views/LegislationPageView.vue"),
  // },
  {
    path: "/policies",
    name: "policies",
    component: () => import("../views/PoliciesPageView.vue"),
    meta: {
      title: "DCUK - Policies",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Policies",
        },
      ],
    },
  },
  {
    path: "/health-safety",
    name: "health and safety",
    component: () => import("../views/HealthSafetyView.vue"),
    meta: {
      title: "DCUK - Health and Safety",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Health and Safety",
        },
      ],
    },
  },
  {
    path: "/sustainability",
    name: "sustainability",
    component: () => import("../views/SustainabilityPageView.vue"),
    meta: {
      title: "DCUK - Sustainability",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Sustainability",
        },
      ],
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/BlogPageView.vue"),
    meta: {
      title: "DCUK - Blog",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Blog",
        },
      ],
    },
  },
  {
    path: "/services/:name",
    name: "view service",
    component: () => import("../views/SectorsServicesView.vue"),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      const serviceName = Array.isArray(to.params.name)
        ? to.params.name[0]
        : to.params.name;
      const metaMap: {
        [key: string]: { title: string; description: string; keywords: string };
      } = {
        asbestos: {
          title: "Asbestos Remediation",
          description:
            "We hold a valid 3-year asbestos licence and have a wealth of experience working in occupied premises.",
          keywords:
            "asbestos, asbestos removal, asbestos remediation, miracle mineral, licenced removal, non licenced, asbestos fibres, encapsulation, notifiable works, soft strip, survey assist, hazardous waste, acad, iosh, asfp, ukata, lpcb",
        },
        "building-refurbishment": {
          title: "Building Refurbishment",
          description:
            "With years of experience spanning across esteemed locations and prestigious clientele, we offer a full spectrum of services, from design conceptualisation to the finishing touches.",
          keywords:
            "refurbishment, interior fit out, shopfitting, design, general building, building refurbishment, soft strip, painting, decorating, painting and decorating, carpentry, floor laying, electrician, electrical installation",
        },
        "ductwork-cleaning": {
          title: "Ductwork Cleaning",
          description:
            "We understand the importance of clean ductwork in maintaining a healthy and comfortable environment.",
          keywords:
            "ductwork cleaning, ductwork, indoor air quality, fan maintenance, contaminated ductwork, fan servicing, htm 03-01, lev, local exhaust ventilation maintenance, ventilation, ventilation cleaning, pre-commission cleaning, ventilation compliance",
        },
        "fan-maintenance": {
          title: "Fan Maintenance",
          description:
            "Providing expert diagnostics and repairs to ensure optimal airflow and peak fan performance in your ventilation systems.",
          keywords:
            "fan maintenance, airflow, ventilation, ventilation system, fan inspections, fan testing, fan cleaning, fan replacement",
        },
        dampers: {
          title: "Fire & Smoke Dampers",
          description:
            "Dampers, when effectively maintained, can save lives by preventing the rapid spread of smoke and fire.",
          keywords:
            "fire dampers, smoke dampers, ventilation systems, damper inspections, fire damper cleaning",
        },
        kitchens: {
          title: "Kitchen Canopy Extract Cleaning",
          description:
            "Kitchen canopies and extract systems, while often overlooked, play a pivotal role in maintaining the hygiene, safety, and efficiency of commercial kitchens.",
          keywords:
            "kitchen canopy, extract cleaning, kitchen extract, extract systems, BESA, TR19, filter replacement, ductwork cleaning, kitchen canopy cleaning",
        },
        "mould-remediation": {
          title: "Mould Remediation",
          description:
            "Dampness and mould not only compromise the structural integrity of buildings but can also lead to significant health problems for occupants",
          keywords:
            "damp, mould, mould remediation, hazard, damp proofing, black mould, condensation, ventilation, building fabric, building maintenance, building refurbishment, damp removal, mould removal, asbestos, asbestos removal, social housing",
        },
        "passive-fire": {
          title: "Passive Fire Protection and Fire Door Services",
          description:
            "DCUK prioritises the secure installation and upkeep of Fire Doors in your establishment",
          keywords:
            "passive fire protection, fire door, installation, fire doors, fire door maintenance, building safety, fire resistance, fire door installation, fire door inspections, compliance reporting, asset tagging, fire stopping, fire compartmentalisation, fire blankets",
        },
        infrastructure: {
          title: "Rail and Transport Infrastructure",
          description:
            "DCUK FM stands as a pioneering contractor in the realm of railway infrastructure, with a rich history of delivering specialized services.",
          keywords:
            "railway, underground, asbestos remediation, track renewals, pigeon guano, lead paint remediation, cable remediation, transport for London, network rail, transport",
        },
        decontamination: {
          title: "Biological Threat Decontamination",
          description:
            "we're at the forefront of delivering professional decontamination and deep cleaning services, ensuring environments are safe and compliant with public health guidelines",
          keywords:
            "covid, decontamination, deep cleaning, covid-19, cbrne, anthrax, mercury, chromium, radon, pigeon guano, lead, biological threat, contaminants",
        },
        "training-academy": {
          title: "Training Academy",
          description:
            "Our blend of theoretical and practical training modules ensures that each attendee leaves with comprehensive knowledge, relevant skills, and the confidence to apply them in real-world scenarios",
          keywords:
            "training, academy, ventilation, ventilation cleaning, ventilation training, fire damper, fire damper testing, fan maintenance, ukata, asbestos, asbestos awareness, first aid training, IOSH, practical training, damp, mould training",
        },
      };

      let metaData;

      for (const key in metaMap) {
        if (key.includes(serviceName)) {
          metaData = metaMap[key];
          break;
        }
      }
      if (metaData) {
        document.title = metaData.title;
        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );
        const metaKeywords = document.querySelector('meta[name="keywords"]');

        if (metaDescription) {
          metaDescription.setAttribute("content", metaData.description);
        } else {
          const meta = document.createElement("meta");
          meta.name = "description";
          meta.content = metaData.description;
          document.getElementsByTagName("head")[0].appendChild(meta);
        }

        if (metaKeywords) {
          metaKeywords.setAttribute("content", metaData.keywords);
        } else {
          const meta = document.createElement("meta");
          meta.name = "keywords";
          meta.content = metaData.keywords;
          document.getElementsByTagName("head")[0].appendChild(meta);
        }
      }
      next();
    },
  },
  {
    path: "/equal-opportunities",
    name: "equal opportunities",
    component: () => import("../views/EqualOpportunitiesView.vue"),
    meta: {
      title: "DCUK - Equal Opportunities",
      metaTags: [
        {
          name: "description",
          content: "DCUK - Equal Opportunities",
        },
      ],
    },
  },
  {
    path: "/services",
    name: "services",
    component: () => import("../views/ServicesPageView.vue"),
    meta: {
      title: "Services",
      metaTags: [
        {
          name: "description",
          content:
            "Experts in asbestos removal, ventilation cleaning, and facilities management, providing clients with a cost effective solutions that minimises disruption and meets health and safety standards",
        },
        {
          name: "keywords",
          content:
            "asbestos, asbestos removal, asbestos remediation, ductwork cleaning, ventilation hygiene, facilities management, innovation, health and safety, building refurbishment, fan maintenance, fire and smoke damper maintenance, global, nationwide, bosiet, non-licence, notifiable, leadership, carbon neutral, sustainability",
        },
      ],
    },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/admin/LoginPage.vue"),
  // },
  // {
  //   path: "/create-account",
  //   name: "create account",
  //   component: () => import("../views/admin/CreateAccount.vue"),
  // },
  // {
  //   path: "/forgot-password",
  //   name: "forgot password",
  //   component: () => import("../views/admin/ForgotPassword.vue"),
  // },
  // {
  //   path: "/create-blog",
  //   name: "create blog",
  //   component: () => import("../views/admin/CreateBlogView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title as string;
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  );
  if (!nearestWithMeta) return next();
  (nearestWithMeta.meta.metaTags as { [key: string]: string }[])
    .map((tagDef) => {
      const tag = document.createElement("meta");
      Object.keys(tagDef).forEach((key) => {
        const value = tagDef[key];
        if (typeof value === "string") {
          tag.setAttribute(key, value);
        }
      });
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

export default router;
